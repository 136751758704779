var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pl-lg-9 pt-9"},[_c('h1',{staticClass:"text-dark font-weight-semi-bold mb-9",domProps:{"innerHTML":_vm._s(_vm.$t('forgetPassword.forgetPassworNewPasswordHtml'))}}),_c('b-form',[_c('b-input-group',{staticClass:"border-light-gray bg-light-gray border-radius-4 bg-white input-field input-field-lg",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticClass:"pl-6 pr-4"},[_c('inline-svg',{staticClass:"d-block",attrs:{"src":require('@/assets/icons/lock.svg'),"height":"14","stroke":"#4E5D78","width":"14"}})],1)]},proxy:true},{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"pl-4 pr-4"},[_c('b-button',{attrs:{"variant":"transparent"},on:{"click":_vm.switchPasswordVisibility}},[_c('inline-svg',{staticClass:"d-block",attrs:{"src":require(`@/assets/icons/${
                  _vm.passwordFieldType === 'text'
                    ? 'show_eye_icon'
                    : 'hide_eye_icon'
                }.svg`),"height":"14","width":"14"}})],1)],1)]},proxy:true}])},[_c('b-form-input',{staticClass:"border-0 font-size-14",attrs:{"type":_vm.passwordFieldType,"placeholder":"Password"},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}})],1),(_vm.hasError('password'))?_c('span',{staticClass:"invalid-feedback",domProps:{"textContent":_vm._s(_vm.hasError('password').error)}}):_vm._e(),_c('b-input-group',{staticClass:"border-light-gray bg-light-gray border-radius-4 mt-9 bg-white input-field input-field-lg",scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{staticClass:"pl-6 pr-4"},[_c('inline-svg',{staticClass:"d-block",attrs:{"src":require('@/assets/icons/lock.svg'),"height":"14","stroke":"#4E5D78","width":"14"}})],1)]},proxy:true},{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"pl-4 pr-4"},[_c('b-button',{attrs:{"variant":"transparent"},on:{"click":_vm.switchPasswordVisibility}},[_c('inline-svg',{staticClass:"d-block",attrs:{"src":require(`@/assets/icons/${
                  _vm.passwordFieldType === 'text'
                    ? 'show_eye_icon'
                    : 'hide_eye_icon'
                }.svg`),"height":"14","width":"14"}})],1)],1)]},proxy:true}])},[_c('b-form-input',{staticClass:"border-0 font-size-14",attrs:{"type":_vm.passwordFieldType,"placeholder":"Confirm Password"},model:{value:(_vm.data.password_confirmation),callback:function ($$v) {_vm.$set(_vm.data, "password_confirmation", $$v)},expression:"data.password_confirmation"}})],1),(_vm.hasError('password_confirmation'))?_c('span',{staticClass:"invalid-feedback",domProps:{"textContent":_vm._s(_vm.hasError('password_confirmation').error)}}):_vm._e(),_c('div',{staticClass:"pt-3"},[_c('b-button',{staticClass:"border-radius-4 px-8 mt-9 mb-9 main-btn main-btn-lg mx-auto",attrs:{"variant":"primary"},on:{"click":_vm.onSubmit}},[(_vm.btnLoading)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("forgetPassword.forgetPassworResetPasswordBtn"))+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }