<template>
  <b-row
    no-gutters
    align-v="center"
    align-h="center"
    class="position-relative my-9"
  >
    <input
      id="security-code"
      ref="input"
      v-model="value"
      class="input-code"
      name="security-code"
      type="tel"
      :maxlength="length"
      autocorrect="off"
      autocomplete="off"
      autocapitalize="off"
      @keyup="handleInput($event)"
    />
    <label for="security-code" class="mb-0">
      <ul class="d-flex mb-0 pl-0">
        <li
          v-for="(_, index) in length"
          :key="index"
          class="d-flex align-items-center justify-content-center border-light-gray border-radius-4 bg-extra-light mx-2 mx-sm-5 security-code-field"
        >
          <span
            v-if="value[index]"
            class="text-color-1 font-size-18 font-weight-medium"
            >{{ value[index] }}</span
          >
          <inline-svg
            v-else
            :src="require('@/assets/icons/security-code.svg')"
            class="d-block"
            height="18"
            width="18"
          ></inline-svg>
        </li>
      </ul>
    </label>
  </b-row>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "SecurityCode",
  // component properties
  props: {
    length: {
      type: Number,
      default: 6
    }
  },
  // variables
  data() {
    return {
      value: ""
    };
  },
  methods: {
    hideKeyboard() {
      document.activeElement.blur();
      this.$refs.input.blur();
    },
    handleSubmit() {
      this.$emit("input", this.value);
    },
    handleInput() {
      // if (e.target.value.length >= this.length) {
      //   this.hideKeyboard();
      // }
      this.handleSubmit();
    }
  }
});
</script>
