
import Vue from "vue";
import SecurityCode from "@/components/SignUp/SecurityCode.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ForgotPasswordEmailCode",
  components: {
    SecurityCode
  },
  data() {
    return {
      data: {
        code: ""
      },
      errors: {},
      btnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      email: "otpcode/getEmail"
    })
  },
  mounted(): void {
    if (this.email === "") {
      this.$router.push("/forgot-password");
    }
  },
  methods: {
    onSubmit() {
      this.btnLoading = true;

      this.$store
        .dispatch("otpcode/VERIFY_OTP", {
          code: this.data.code,
          email: this.email
        })
        .then(() => {
          this.btnLoading = false;
          // @ts-ignore
          this.$alertify.success(
            (this as any).$t("forgetPassword.forgetPassworOTPVerifiedMessage")
          );

          this.$router.push("/forgot-password/new-password");
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
