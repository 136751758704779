
import Vue from "vue";
import ForgotPasswordEmail from "@/components/ForgotPassword/ForgotPasswordEmail.vue";
import ForgotPasswordEmailCode from "@/components/ForgotPassword/ForgotPasswordEmailCode.vue";
import ForgotPasswordLogin from "@/components/ForgotPassword/ForgotPasswordLogin.vue";
import ForgotPasswordNewPassword from "@/components/ForgotPassword/ForgotPasswordNewPassword.vue";

export default Vue.extend({
  name: "ForgotPassword",
  components: {
    ForgotPasswordEmail,
    ForgotPasswordEmailCode,
    ForgotPasswordLogin,
    ForgotPasswordNewPassword
  },
  data() {
    return {
      activeComponent: {
        email: "ForgotPasswordEmail",
        code: "ForgotPasswordEmailCode",
        "new-password": "ForgotPasswordNewPassword",
        login: "ForgotPasswordLogin"
      },
      isPlay: false,
      videoId: "tnpXX1Xp1vM"
    };
  },
  computed: {
    player() {
      //@ts-ignore
      return this.$refs.youtube.player;
    }
  },
  methods: {
    playVideo() {
      this.isPlay = true;
      this.player.playVideo();
    },
    playing() {
      console.log(" we are watching!!!");
    }
  }
});
