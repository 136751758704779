
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ForgotPasswordNewPassword",
  components: {},
  data() {
    return {
      data: {
        password: "",
        password_confirmation: ""
      },
      errors: {},
      btnLoading: false,
      passwordFieldType: "password"
    };
  },
  computed: {
    ...mapGetters({
      email: "otpcode/getEmail"
    })
  },
  mounted(): void {
    if (this.email === "") {
      this.$router.push("/forgot-password");
    }
  },
  methods: {
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    onSubmit() {
      this.btnLoading = true;

      this.$store
        .dispatch("auth/RESET_PASSWORD", {
          ...this.data,
          ...{ email: this.email }
        })
        .then(() => {
          this.btnLoading = false;
          //@ts-ignore
          this.$alertify.success(
            (this as any).$t(
              "forgetPassword.forgetPassworResetPasswordSuccessMessage"
            )
          );

          this.$store.commit("otpcode/SET_EMAIL", "");

          this.$router.push("/forgot-password/login");
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
