
import Vue from "vue";

export default Vue.extend({
  name: "ForgotPasswordEmail",
  components: {},
  data() {
    return {
      data: {
        email: "",
        otp_category_code_id: "2"
      },
      errors: {},
      btnLoading: false
    };
  },
  methods: {
    onSubmit() {
      this.btnLoading = true;

      this.$store
        .dispatch("otpcode/GENERATE_OTP", this.data)
        .then(() => {
          this.btnLoading = false;
          // @ts-ignore
          this.$alertify.success(
            (this as any).$t(
              "forgetPassword.forgetPassworOTPSentOnEmailMessage"
            )
          );

          this.$router.push("/forgot-password/code");
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            if (
              error.response.data.errors.email &&
              error.response.data.errors.email[0] ===
                "The selected email is invalid."
            ) {
              this.$router.push({
                name: "SignUpPageMentoree",
                params: { step: "email-not-registered" }
              });
            }
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
